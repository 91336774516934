// *********************************************************
// Manitech
// *********************************************************

.manitech__bg {
    position: relative;
    min-height: rem(200);
    // background-image: url("../images/page/manitech_sm.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 2;


    @media only screen and (min-width: $small-device) {
        min-height: rem(300);  
        // background-image: url("../images/page/manitech_md.jpg");
    }

    @media only screen and (min-width: $medium-device) {
        min-height: rem(400);  
        // background-image: url("../images/page/manitech_lg.jpg");
        background-attachment: fixed;
    }

    @media only screen and (min-width: $large-device) {
        min-height: rem(721);  
        // background-image: url("../images/page/manitech.jpg");
    }
}

.manitech__info {
    padding-top: rem(46);
    padding-bottom: rem(48);

    // 2. card 

    [class~="col"]:last-child .card, [class*="col-"]:last-child .card {
        padding-top: rem(64);
        margin-top: rem(-125);
        max-width: rem(480);

        @media only screen and (max-width: $medium-device) {
            margin-top: rem(48);
            margin-left: auto;
            margin-right: auto;
        }

        .card__wrapper {
            padding-left: rem(64);
            padding-right: rem(58);

            @media only screen and (max-width: $medium-device) {
                padding-left: rem(30);
                padding-right: rem(30);
            }
        }

        .card__text:not(:last-child)  {
            margin-bottom: rem(24);
        }
    }

    // 1. card 

    [class~="col"]:first-child .card, [class*="col-"]:first-child .card {
        padding-top: rem(42);
        padding-bottom: rem(47);
        max-width: rem(529);

        @media only screen and (max-width: $medium-device) {
            margin: 0 auto;
        }

        .card__wrapper {
            padding-left: rem(100);
            padding-right: rem(42);

            @media only screen and (max-width: $large-device) {
                padding-left: rem(64);
                padding-right: rem(42);
            }

            @media only screen and (max-width: $medium-device) {
                padding-left: rem(30);
                padding-right: rem(30);
            }
        }
    }

    // 1. card all paragraph 

    [class~="col"]:first-child .card__text, [class*="col-"]:first-child .card__text {
        font-family: "Titillium Web";
        font-size: rem(18);
        font-weight: 300;
    }

    // 1. card paragraph nth child odd

    [class~="col"]:first-child .card__text:nth-child(odd), [class*="col-"]:first-child .card__text:nth-child(odd) {
        color: $secondary-color;
    }

    // 1. card paragraph not last child

    [class~="col"]:first-child .card__text:not(:last-child), [class*="col-"]:first-child .card__text:not(:last-child){
        margin-bottom: rem(24);
    }

    .card .card__text {
        font-family: "Khand";
        font-size: rem(20);
    }

    .card__wrapper {
        @media only screen and (max-width: $medium-device) {
            overflow-y: initial;
        }
    }

    &-text-wrapper {
        margin-top: rem(38);

        @media only screen and (max-width: $medium-device) {
            padding: 0 rem(15);
        }
    }

    &-paragraph {
        font-family: "Titillium Web";
        font-size: rem(18);
        font-weight: normal;
        margin-bottom: rem(38);
        max-width: rem(480);

        @media only screen and (max-width: $medium-device) {
            max-width: none;
        }
    }

    &-paragraph:last-child {
        margin-bottom: rem(0);
    }
}