// *********************************************************
// Full background video
// *********************************************************

.video {
    position: fixed;
    top: 0;
    right: rem(-250);
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;

    @media only screen and (max-width: $medium-device) {
        right: 0;
    }

    &:after, &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    
    &:before {
        content: "";
        background-image: url("../images/slider/slide1.jpg");
        background-position: 33% center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &:after {
        content: "";
    }
}
  
.video-ready {

    .vidage:before {
        display: none;
    }

    .video-el {
        display: block;
    }
}
  
  
.video-el {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    display: none;
    pointer-events: none;
}
  
@supports not ((-o-object-fit: cover) or (object-fit: cover)) {
    
    .video-el {
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }
}