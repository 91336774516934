// *********************************************************
// Card
// *********************************************************

.card {
    position: relative;
    color: #FFFFFF;
    background: #000000;
    z-index: 9;
    border: none;
    border-radius: 0;


    &__heading {
        font-size: rem(35);
        font-weight: normal;
        line-height: 1;
        text-transform: uppercase;
    }

    &__wrapper {
        overflow-y: auto;
        padding: 0 rem(40);

        @media only screen and (max-width: $medium-device) {
            padding: 0 rem(28);
        }

        &::-webkit-scrollbar {
            width: rem(5);
        }
        
        /* Track */
    
        &::-webkit-scrollbar-track {
            background: #FFFFFF;
        }
        
        /* Handle */
    
        &::-webkit-scrollbar-thumb {
            background: $primary-color; 
        }
        
        /* Handle on hover */
    
        &::-webkit-scrollbar-thumb:hover {
            background: $primary-color; 
        }
    }

    &__btn-wrapper {
        margin-top: rem(70);
    }

    &__text {
        font-size: rem(16);
        font-family: "Titillium Web";
        font-weight: 300;
    }

}

.card--white {
    color: #000000;
    background: #FFFFFF;
    border: none;
    border-radius: 0;
}