// *********************************************************
// Contact
// *********************************************************

.contact__card {
    position: relative;
    background: #EBEBEB;
    padding: rem(15) rem(30);
    overflow: hidden;

    &:not(:last-child) {
        margin-bottom: rem(32);
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: rem(-60);
        left: rem(60);
        height: rem(250);
        width: rem(27);
        background: $primary-color;

        transform: rotate(45deg);
    }

    &-heading {
        font-family: "Khand";
        font-size: rem(54);
        font-weight: 500;
        margin-top: rem(24);
        text-transform: uppercase;

        @media only screen and (max-width: $medium-device) {
            text-align: center;
        }
    }

    &-container {
        overflow-y: auto;
        height: rem(650);
        
        &::-webkit-scrollbar {
            width: rem(5);
        }
        
        /* Track */
    
        &::-webkit-scrollbar-track {
            background: #FFFFFF;
        }
        
        /* Handle */
    
        &::-webkit-scrollbar-thumb {
            background: #555555; 
            border-radius: 25px;
        }
        
        /* Handle on hover */
    
        &::-webkit-scrollbar-thumb:hover {
            background: #555555;
        }
        

        @media only screen and (max-width: $medium-device) {
            overflow-y: initial;
            height: auto;
        }
    }

    &-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        @media only screen and (max-width: $medium-device) {
            flex-wrap: wrap;
        }
    }

    // -------------------------------
    // 1. section contact card

    &-box-photo {
        position: relative;
        width: rem(120);
        margin-right: rem(45);
        z-index: 2;

        @media only screen and (max-width: $medium-device) {
            width: 100%;
            margin-bottom: rem(24);
            margin-right: 0;
        }
    }

    &-photo-wrapper {
        width: rem(120);
        height: rem(120);
        border-radius: 50%;
        border: 4px solid #B7B7B7;
        overflow: hidden;

        @media only screen and (max-width: $medium-device) {
            margin: 0 auto;
        }
    }

    &-photo {
        width: 100%;
    }

    // -------------------------------
    // 2. section contact card

    &-box-info {
        flex-grow: 1;

        @media only screen and (max-width: $medium-device) {
            width: 100%;
            text-align: center;
        }
    }

    &-info-name {
        font-size: rem(31);
        font-weight: 600;
        margin-bottom: 0;
        letter-spacing: em(1);
        text-transform: uppercase;

        @media only screen and (max-width: $large-device) {
            font-size: rem(24);
        }
    }

    &-info-position {
        position: relative;
        font-family: "Titillium Web";
        font-size: rem(14);
        font-weight: 300;
        margin: 0;
    }

    &-info-position::before {
        content: "";
        display: block;
        position: absolute;
        bottom: rem(-12);
        right: rem(-30);
        height: rem(1);
        width: calc(100% + 30px);
        background: #000000;

        @media only screen and (max-width: $medium-device) {
            width: 100%;
            right: 0;
        }
    }

    &-info-list {
        font-family: "Titillium Web";
        font-size: rem(14);
        font-weight: 300;
        list-style: none;
        margin: rem(24) 0 0 0;
        padding: 0;
    }

    &-info-item {
        @media only screen and (max-width: $medium-device) {
            margin-bottom: rem(8);
        }
    }

    &-info-link,
    &-info-link:link,
    &-info-link:visited {
        color: #000000;
    }

    &-footer {
        text-align: right;
    }

    &-cta,
    &-cta:link,
    &-cta:visited {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #000000;
        width: rem(107);
        height: rem(35);
        margin-right: rem(-30);
        margin-left: auto;
        margin-bottom: rem(-15);
        line-height: 1;
        background: #B7B7B7;

        @media only screen and (max-width: $medium-device) {
            margin: rem(15) auto;
        }
    }
}

// Map iframe wrapper

.contact__map-wrapper {
    
    @media only screen and (max-width: $medium-device) {
        margin-top: rem(48);
    }
}   

// Address

.contact__address {
    font-family: "Titillium Web";
    font-size: rem(14);
    font-weight: 300;

    strong, b {
        font-weight: 600;
    }
}