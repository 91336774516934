// *********************************************************
// Fuel consumption
// *********************************************************

// Fule consumption info section

.fuel-consumption__info {

    // Card in monitoring info

    .card {
        padding-top: rem(66);
        max-height: rem(696);
        max-width: rem(417);
        margin-bottom: rem(-74);

        @media only screen and (max-width: $medium-device) {
            margin-top: rem(48);
            max-height: none;
            margin: 0 auto;
            margin-bottom: 0;
        }

        @media only screen and (max-width: $small-device) {
            margin-top: 15rem;
        }
    }

    .card .card__heading {
        font-family: "Khand";
        font-size: rem(38);
        font-weight: 500;
    }

    .card .card__text {
        font-family: "Khand";
        font-size: rem(20);
    }

    .card__wrapper {
        padding-left: rem(59); 
        padding-right: rem(34);

        @media only screen and (max-width: $medium-device) {
            overflow-y: initial;
        }
    }

    // Info header

    &-header {
        position: relative;
        // background-image: url("../images/page/meranie-spotreby_sm.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        z-index: 2;

        .row {
            min-height: rem(200);
            
            @media only screen and (min-width: $small-device) {
                min-height: rem(300);  
            }
    
            @media only screen and (min-width: $medium-device) {
                min-height: rem(400);  
            }
    
            @media only screen and (min-width: $large-device) {
                min-height: rem(611);  
            }
        }

        @media only screen and (min-width: $small-device) { 
            // background-image: url("../images/page/meranie-spotreby_md.jpg");
        }

        @media only screen and (min-width: $medium-device) { 
            // background-image: url("../images/page/meranie-spotreby_lg.jpg");
            background-attachment: fixed;
        }

        @media only screen and (min-width: $large-device) {
            // background-image: url("../images/page/meranie-spotreby.jpg");
        }
    }

    &-paragraph {
        font-family: "Titillium Web";
        font-size: rem(18);
        font-weight: 300;
        color: #FFFFFF;
        max-width: rem(564);
    }

    &-paragraph:first-child {
        margin-top: rem(81);

        @media only screen and (max-width: $medium-device) {
            margin-top: 0;
        }
    }

    &-paragraph:last-child {
        margin-bottom: rem(54);
    }

    &-paragraph:not(:last-child) {
        margin-bottom: rem(34);
    }

    &-text {
        font-size: rem(20);
        font-weight: 300;
        line-height: 1.5;
        margin-top: rem(117);
        max-width: rem(417);

        
        @media only screen and (max-width: $medium-device) {
            margin: rem(34) auto;
            text-align: center;
        }
    }

    &-paragraph:nth-child(even) {
        color: $secondary-color;
    }


    &-body {
        background: #000000;
        padding-bottom: rem(60);

        @media only screen and (max-width: $small-device) {
            padding-bottom: rem(48); 
        }
    }
}