// *********************************************************
// Icons
// *********************************************************
// Icon path
$icon-path: "../../fonts/icons";

@font-face {
    font-family: "infocar";
    src: url("#{$icon-path}/infocar.eot");
    src: url("#{$icon-path}/infocar.eot?#iefix") format("embedded-opentype"),
        url("#{$icon-path}/infocar.woff") format("woff"),
        url("#{$icon-path}/infocar.ttf") format("truetype"),
        url("#{$icon-path}/infocar.svg#infocar") format("svg");
    font-weight: normal;
    font-style: normal;
  
}
  
[data-icon]:before {
    font-family: "infocar" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "infocar" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-top: em(1.5);
}

[class^="icon-"],
[class*=" icon-"] {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
  
.icon-about-us:before {
    content: "\61";
}

.icon-brain:before {
    content: "\62";
}

.icon-cash-flow:before {
    content: "\63";
}

.icon-certificate:before {
    content: "\64";
}
  
.icon-close:before {
    content: "\65";
}

.icon-consumption:before {
    content: "\66";
}

.icon-control:before {
    content: "\67";
}

.icon-ecodrive:before {
    content: "\68";
}

.icon-facebook:before {
    content: "\69";
}

.icon-google-plus:before {
    content: "\6a";
}

.icon-infocare:before {
    content: "\6b";
}

.icon-logistic:before {
    content: "\6c";
}

.icon-manitech:before {
    content: "\6d";
}

.icon-zoom:before {
    content: "\6e";
}

.icon-vehicle-monitoring:before {
    content: "\6f";
}

.icon-twitter:before {
    content: "\70";
}

.icon-trial:before {
    content: "\71";
}

.icon-touch:before {
    content: "\72";
}

.icon-savings:before {
    content: "\73";
}

.icon-rides-book:before {
    content: "\74";
}

.icon-profil-company:before {
    content: "\75";
}

.icon-price:before {
    content: "\76";
}
  