// *********************************************************
// Heading
// *********************************************************

.heading--horizontal {
    font-size: rem(64);
}

.heading--vertical {
    font-size: rem(81);
}
