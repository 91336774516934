// *********************************************************
// Infocare
// *********************************************************

// Infocare info section

.infocare__info {
    background: #000000;

    &-paragraph {
        font-family: "Titillium Web";
        font-size: rem(18);
        font-weight: 300;
        color: #FFFFFF;
    }

    &-paragraph:not(:last-child) {
        margin-bottom: rem(24);
    }

    .card {
        height: calc(100% + 27px);
        max-width: rem(480);

        @media only screen and (max-width: $medium-device) {
            height: auto;
            margin: 0 auto;
        }
    }

    .card__wrapper {
        padding-left: rem(63);
        padding-right: rem(52);
        height: 100%;

        @media only screen and (max-width: $large-device) {
            padding: 0 rem(32);
        }
    }

    .card__heading {
        font-weight: 500;
        padding: 0;
    }

    .card__text-wrapper {
        padding: 0;
    }

    .card__text {
        font-family: "Khand";
        font-size: rem(20);
        font-weight: 300;
        margin-bottom: rem(24);
    }

    .card__btn-wrapper {
        margin-top: 0;
    }

    &-text-wrapper {
        margin-top: rem(60);
        margin-bottom: rem(70);
        max-width: rem(480);
    }
}

// Infocare cta section

.infocare__cta {
    background: #D7D7D7;

    &-text-wrapper {
        margin-bottom: rem(48);
        margin-top: rem(52); 
        max-width: rem(480);

        @media only screen and (max-width: $medium-device) {
            margin: 0 auto;
        }

    }

    &-paragraph {
        font-size: rem(22);
        text-align: center;

        @media only screen and (max-width: $medium-device) {
            margin: rem(48) auto rem(72) auto;
        }
    }

    .card {
        padding: rem(70) 0;
        margin-top: rem(-30);
        height: calc( 100% + 60px);
        min-height: rem(615);
        max-width: rem(433);

        @media only screen and (max-width: $medium-device) {
            height: 100%;
            margin: 0 auto;
        }

    }

    .card__wrapper {
        padding: 0 rem(60);

        @media only screen and (max-width: $medium-device) {
            padding: 0 rem(30);
        }
    }

    .card__text {
        font-family: "Khand";
        font-size: rem(20);
        font-weight: normal;
    }

    .card__text:first-child .btn {
        margin-bottom: rem(64);
    }

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem(74);
        margin-top: rem(28);
        line-height: 1;
        @media only screen and (max-width: $medium-device) {
            font-size: rem(14);
        }
    }

}