// *********************************************************
// Services list
// *********************************************************

// services accordion

.services {
    overflow-x: hidden;

    &__heading {
        display: flex;
        justify-content: flex-start;
        font-size: rem(18);
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: em(4);
        white-space: normal;
    }

    &__heading::before {
        content: "";
        margin-top: rem(-1);
        border-left: 6px solid $primary-color;
        vertical-align: middle;
        margin-right: rem(12);
    }

    &__box {
        counter-increment: inst;
        padding: rem(24) 0;
    }

    &__item {
        position: relative;
        font-size: rem(60);
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
        line-height: 1.2;
        margin-left: rem(40);
        margin-bottom: rem(-2);

        @media only screen and (max-width: $large-device) {
            font-size: rem(48);
        }

        @media only screen and (max-width: $medium-device) {
            font-size: rem(28);
        }

        @media only screen and (max-width: $small-device) {
            font-size: rem(20);
        }
    }

    &__item::before {
        content: counter(inst);
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: -1px;
        left: rem(-40);
        font-size: rem(16);
        margin-right: rem(24);
        transition: all 0.2s cubic-bezier(0.51, 0.22, 0.36, 1.08);
        backface-visibility: hidden;
    }

    &__item--active::before {
        transform: scale(2);
        color: $primary-color;
        left: rem(-30);
    }

    &__box:not(:first-child) {
        border-top: 1px solid #000000;
    }

    &__box:not(:last-child) {
        border-top: 1px solid #000000;
    }

    &__box:last-child {
        border-bottom: 1px solid #000000;
    }

    &__text {
        display: none;
        margin-left: rem(40);
        padding-right: rem(40);
    }

    &__text p::before {
        content: "–";
        display: inline;
    }

    &__text p {
        padding-top: rem(24);
    }

    &__text--default {
        display: block;
    }

    &__text-small {
        font-weight: 300;
    }

    // Hover 

    &__item:hover::before {
        
        @media only screen and (min-width: $small-device), (any-hover: hover) {
            color: $primary-color;
            left: rem(-30);

        }
    }
}