// *********************************************************
// Download card
// *********************************************************

.download-card {
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    max-width: rem(769);
    min-height: rem(400);
    background: rgba(222, 246, 254, 0.8);

    &__header {
        display: flex;
        align-items: center;
        height: rem(97);
        border-top: 6px solid $primary-color;
        border-bottom: 1px solid #8E8E8E;
        padding: 0 rem(44);
        margin-bottom: rem(18);
    }

    &__heading {
        font-family: "Khand";
        font-size: rem(37);
        font-weight: 500;
        color: #8E8E8E;
        text-transform: uppercase;
    }

    &__sub-heading {
        font-family: "Titillium Web";
        font-size: rem(16);
        font-weight: 600;
        color: #8E8E8E;
        margin-bottom: rem(10);
        text-transform: uppercase;
    }

    &__body {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        padding-bottom: rem(42);

        @media only screen and (max-width: $medium-device) {
            flex-wrap: wrap;
        }
    }

    &__box {
        border: 1px solid #8E8E8E;
        padding: rem(14);
        flex: 1;
        min-height: rem(215);

        @media only screen and (max-width: $medium-device) {
            flex-basis: 100%;
        }
    }

    &__box:first-child {
        margin-left: rem(44);
        margin-right: rem(15);

        @media only screen and (max-width: $medium-device) {
            margin: rem(15) rem(30);
        }

    }

    &__box:last-child {
        margin-right: rem(44);
        margin-left: rem(15);

        @media only screen and (max-width: $medium-device) {
            margin: rem(15) rem(30);
        }
    }

    &__link,
    &__link:link,
    &__link:visited {
        font-family: "Titillium Web";
        font-size: rem(16);
        color: #8E8E8E;
    }
}