
// *********************************************************
// Monitoring
// *********************************************************

// Monitoring info section

.monitoring__info {

    // Card in monitoring info

    .card {
        max-width: rem(417);
        max-height: rem(768);
        padding-top: rem(67);
        margin-top: rem(-368);


        @media only screen and (max-width: $medium-device) {
            margin-top: rem(48);
            max-height: none;
            max-width: none;
        }
    }

    .card .card__heading {
        font-family: "Khand";
        font-size: rem(38);
        font-weight: 500;
    }

    .card .card__text {
        font-family: "Khand";
        font-size: rem(20);
        font-weight: 300;
        margin-bottom: rem(46);
        line-height: 1.3;
    }

    .card .card__text:last-child {
        margin-bottom: rem(61);
    }

    .card__wrapper {
        padding-left: rem(58);
        padding-right: rem(40);

        @media only screen and (max-width: $medium-device) {
            overflow-y: initial;
            padding-left: rem(30);
            padding-right: rem(30);
        }
    }

    .card__btn-wrapper {
        margin: 0;
    }

    .btn {
        height: rem(74);
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // Info header

    &-header {
        position: relative;
        min-height: rem(200);
        // background-image: url("../images/page/monitoring-vozidiel-servis_sm.jpg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
        z-index: 1;


        @media only screen and (min-width: $small-device) {
            min-height: rem(300);  
            // background-image: url("../images/page/monitoring-vozidiel-servis_md.jpg");
            background-size: cover;
        }

        @media only screen and (min-width: $medium-device) {
            min-height: rem(400);  
            // background-image: url("../images/page/monitoring-vozidiel-servis_lg.jpg");
            background-attachment: fixed;
        }

        @media only screen and (min-width: $large-device) {
            min-height: rem(611);  
            // background-image: url("../images/page/monitoring-vozidiel-servis.jpg");
        }
    }

    &-paragraph {
        font-family: "Titillium Web";
        font-size: rem(18);
        font-weight: 300;
        color: #FFFFFF;
        max-width: rem(564);

        @media only screen and (max-width: $medium-device) {
            max-width: none;
        }
    }

    &-paragraph:first-child {
        margin-top: rem(81);
    }

    &-paragraph:last-child {
        margin-bottom: rem(54);
    }

    &-paragraph:not(:last-child) {
        margin-bottom: rem(34);
    }

    &-paragraph:nth-child(even) {
        color: $secondary-color;
    }

    &-text {
        font-size: rem(20);
        font-weight: 300;
        line-height: 1.5;
        margin-top: rem(43);
    }

    &-body {
        background: #000000;
        padding-bottom: rem(98);

        @media only screen and (max-width: $small-device) {
            padding-bottom: rem(48); 
        }
    }
}

// Monitoring cta section

.monitoring__cta {
    position: relative;
    // background-image: url("../images/page/monitoring-vozidiel-demo_sm.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 2;
    padding: rem(80) 0;


    // @media only screen and (min-width: $small-device) {
    //     background-image: url("../images/page/monitoring-vozidiel-demo_md.jpg");
    // }

    @media only screen and (min-width: $medium-device) {  
        // background-image: url("../images/page/monitoring-vozidiel-demo_lg.jpg");
        background-attachment: fixed;
        min-height: none;
    }

    @media only screen and (min-width: $large-device) {
        min-height: rem(774);
        // background-image: url("../images/page/monitoring-vozidiel-demo.jpg");
    }

    .card {
        padding: rem(70) 0;
        min-height: rem(615);
        max-width: rem(433);

        @media only screen and (max-width: $medium-device) {
            margin: 0 auto;
        }
    }

    .card__wrapper {
        padding: 0 rem(60);

        @media only screen and (max-width: $medium-device) {
            padding: 0 rem(30);
        }
    }

    .card__text {
        font-family: "Khand";
        font-size: rem(20);
        font-weight: normal;
    }

    .card__text:first-child .btn {
        margin-bottom: rem(64);
    }

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem(74);
        margin-top: rem(28);
        line-height: 1;
        @media only screen and (max-width: $medium-device) {
            font-size: rem(14);
        }
    }
}