// *********************************************************
// Book rides
// *********************************************************

// Book rides info section

.book-rides__info {
    background: #000000;
    min-height: rem(377);

    &-wrapper {
        padding-top: rem(60);
        padding-bottom: rem(43);
    }

    &-paragraph {
        font-family: "Titillium Web";
        font-size: rem(18);
        font-weight: 300;
        color: #FFFFFF;
    }

    &-paragraph:not(:last-child) {
        margin-bottom: rem(34);
    }

    .card {
        height: 100%;
        max-width: rem(480);
        

        @media only screen and (max-width: $medium-device) {
            margin: 0 auto;
        }
    }

    .card__wrapper {
        padding-left: rem(63);
        padding-right: rem(52);
        height: 100%;
        overflow-y: initial;

        @media only screen and (max-width: $large-device) {
            padding: rem(64) rem(30) 0 rem(30);
        }
    }

    .card__heading {
        font-weight: 500;
        padding: 0;
    }

    .card__text-wrapper {
        padding: 0;
    }

    .card__text {
        font-family: "Khand";
        font-size: rem(20);
        font-weight: 300;
        margin-bottom: rem(28);
    }

    .card__btn-wrapper {
        margin: 0;
    }
}

// Book rides sub info section 

.book-rides__sub-info {
    font-family: "Titillium Web";
    font-size: rem(18);
    font-weight: normal;
    background: #D7D7D7;
    padding: rem(45) rem(15);

    &-paragraph {
        max-width: rem(698);
        margin: 0 auto;
    }

    p::selection  {
        color: $secondary-color;
        background: transparent;
    }

    p::-moz-selection {
        color: $secondary-color;
        background: transparent;
    }
}

// Book rides more info card section

.book-rides__more-info {

    .card {
        padding-top: rem(46);
        padding-bottom: rem(30);
        max-width: rem(630);
        margin-left: auto;
        margin-right: auto;
        margin-top: rem(30);

        @media only screen and (max-width: $medium-device) {
            margin-top: 0;
        }
    }

    .card__wrapper {
        overflow-y: initial;
        padding: 0 rem(44);

        @media only screen and (max-width: $medium-device) {
            padding: 0 rem(30);   
        }
    }

    .card__text {
        text-align: center;
    }
    

    .card__text:not(:last-child) {
        margin-bottom: rem(16);
        padding-bottom: rem(19);
        border-bottom: 1px solid #FFFFFF;
    }

    .card__btn-wrapper {
        margin: rem(24) 0 0 0;
    }
}

// Book rides cta section

.book-rides__cta {
    position: relative;
    // background-image: url("../images/page/kniha-jazd_sm.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 2;
    padding: rem(40) 0;


    @media only screen and (min-width: $small-device) {
        // background-image: url("../images/page/kniha-jazd_md.jpg");
    }

    @media only screen and (min-width: $medium-device) {  
        // background-image: url("../images/page/kniha-jazd_lg.jpg");
        background-attachment: fixed;
    }

    @media only screen and (min-width: $large-device) {
        padding: rem(80) 0;
        // background-image: url("../images/page/kniha-jazd.jpg");
    }

    .card {
        padding: rem(70) 0;
        min-height: rem(615);
        max-width: rem(433);

        @media only screen and (max-width: $medium-device) {
            margin: 0 auto;
        }
    }

    .card__wrapper {
        padding: 0 rem(60);

        @media only screen and (max-width: $medium-device) {
            padding: 0 rem(30);
        }
    }

    .card__text {
        font-family: "Khand";
        font-size: rem(20);
        font-weight: normal;
    }

    .card__text:first-child .btn {
        margin-bottom: rem(64);
    }

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem(74);
        margin-top: rem(28);
        line-height: 1;
        @media only screen and (max-width: $medium-device) {
            font-size: rem(14);
        }
    }
}