// *********************************************************
// Download 
// *********************************************************

.download {
    padding: rem(15);
    min-height: 100vh;
    background-image: url("../images/page/nastiahnutie.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}   