// *********************************************************
// Typography
// *********************************************************

// FONTS
// font path
$font-path: "../../fonts";

// Font Khand

@font-face {
    font-family: "Khand";
    src: url("#{$font-path}/Khand-SemiBold.eot");
    src: url("#{$font-path}/Khand-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Khand-SemiBold.woff2") format("woff2"),
        url("#{$font-path}/Khand-SemiBold.woff") format("woff"),
        url("#{$font-path}/Khand-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Khand";
    src: url("#{$font-path}/Khand-Medium.eot");
    src: url("#{$font-path}/Khand-Medium.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Khand-Medium.woff2") format("woff2"),
        url("#{$font-path}/Khand-Medium.woff") format("woff"),
        url("#{$font-path}/Khand-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Khand";
    src: url("#{$font-path}/Khand-Regular.eot");
    src: url("#{$font-path}/Khand-Regular.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Khand-Regular.woff2") format("woff2"),
        url("#{$font-path}/Khand-Regular.woff") format("woff"),
        url("#{$font-path}/Khand-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Khand";
    src: url("#{$font-path}/Khand-Light.eot");
    src: url("#{$font-path}/Khand-Light.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Khand-Light.woff2") format("woff2"),
        url("#{$font-path}/Khand-Light.woff") format("woff"),
        url("#{$font-path}/Khand-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Khand";
    src: url("#{$font-path}/Khand-Bold.eot");
    src: url("#{$font-path}/Khand-Bold.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/Khand-Bold.woff2") format("woff2"),
        url("#{$font-path}/Khand-Bold.woff") format("woff"),
        url("#{$font-path}/Khand-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

// Font Titillium

@font-face {
    font-family: "Titillium Web";
    src: url("#{$font-path}/TitilliumWeb-SemiBold.eot");
    src: url("#{$font-path}/TitilliumWeb-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/TitilliumWeb-SemiBold.woff2") format("woff2"),
        url("#{$font-path}/TitilliumWeb-SemiBold.woff") format("woff"),
        url("#{$font-path}/TitilliumWeb-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Titillium Web";
    src: url("#{$font-path}/TitilliumWeb-ExtraLight.eot");
    src: url("#{$font-path}/TitilliumWeb-ExtraLight.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/TitilliumWeb-ExtraLight.woff2") format("woff2"),
        url("#{$font-path}/TitilliumWeb-ExtraLight.woff") format("woff"),
        url("#{$font-path}/TitilliumWeb-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Titillium Web";
    src: url("#{$font-path}/TitilliumWeb-Bold.eot");
    src: url("#{$font-path}/TitilliumWeb-Bold.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/TitilliumWeb-Bold.woff2") format("woff2"),
        url("#{$font-path}/TitilliumWeb-Bold.woff") format("woff"),
        url("#{$font-path}/TitilliumWeb-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Titillium Web";
    src: url("#{$font-path}/TitilliumWeb-LightItalic.eot");
    src: url("#{$font-path}/TitilliumWeb-LightItalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/TitilliumWeb-LightItalic.woff2") format("woff2"),
        url("#{$font-path}/TitilliumWeb-LightItalic.woff") format("woff"),
        url("#{$font-path}/TitilliumWeb-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Titillium Web";
    src: url("#{$font-path}/TitilliumWeb-Italic.eot");
    src: url("#{$font-path}/TitilliumWeb-Italic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/TitilliumWeb-Italic.woff2") format("woff2"),
        url("#{$font-path}/TitilliumWeb-Italic.woff") format("woff"),
        url("#{$font-path}/TitilliumWeb-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Titillium Web";
    src: url("#{$font-path}/TitilliumWeb-Black.eot");
    src: url("#{$font-path}/TitilliumWeb-Black.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/TitilliumWeb-Black.woff2") format("woff2"),
        url("#{$font-path}/TitilliumWeb-Black.woff") format("woff"),
        url("#{$font-path}/TitilliumWeb-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Titillium Web";
    src: url("#{$font-path}/TitilliumWeb-ExtraLightItalic.eot");
    src: url("#{$font-path}/TitilliumWeb-ExtraLightItalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/TitilliumWeb-ExtraLightItalic.woff2") format("woff2"),
        url("#{$font-path}/TitilliumWeb-ExtraLightItalic.woff") format("woff"),
        url("#{$font-path}/TitilliumWeb-ExtraLightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "Titillium Web";
    src: url("#{$font-path}/TitilliumWeb-Regular.eot");
    src: url("#{$font-path}/TitilliumWeb-Regular.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/TitilliumWeb-Regular.woff2") format("woff2"),
        url("#{$font-path}/TitilliumWeb-Regular.woff") format("woff"),
        url("#{$font-path}/TitilliumWeb-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Titillium Web";
    src: url("#{$font-path}/TitilliumWeb-BoldItalic.eot");
    src: url("#{$font-path}/TitilliumWeb-BoldItalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/TitilliumWeb-BoldItalic.woff2") format("woff2"),
        url("#{$font-path}/TitilliumWeb-BoldItalic.woff") format("woff"),
        url("#{$font-path}/TitilliumWeb-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Titillium Web";
    src: url("#{$font-path}/TitilliumWeb-Light.eot");
    src: url("#{$font-path}/TitilliumWeb-Light.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/TitilliumWeb-Light.woff2") format("woff2"),
        url("#{$font-path}/TitilliumWeb-Light.woff") format("woff"),
        url("#{$font-path}/TitilliumWeb-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Titillium Web";
    src: url("#{$font-path}/TitilliumWeb-SemiBoldItalic.eot");
    src: url("#{$font-path}/TitilliumWeb-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}/TitilliumWeb-SemiBoldItalic.woff2") format("woff2"),
        url("#{$font-path}/TitilliumWeb-SemiBoldItalic.woff") format("woff"),
        url("#{$font-path}/TitilliumWeb-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}

html {
    font-family: "Khand";
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    -webkit-text-size-adjust: 100%; 
    -ms-text-size-adjust: 100%; 
    -ms-overflow-style: scrollbar;
    scroll-behavior: smooth;
    // background: #FFFFFF;
}

body {
    font-family: "Khand";
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    color: #000000;

    overflow-x: hidden;
}

.text--strong-b {
    color: $secondary-color !important;     
}

.text--strong-y {
    color: $primary-color !important;

    &::-moz-selection { 
        color: $secondary-color;
        background: transparent;
    }
    
    &::selection {
        color: $secondary-color;
        background: transparent;
    }
}

::-moz-selection { 
    color: $primary-color;
    background: transparent;
}

::selection {
    color: $primary-color;
    background: transparent;
}
