// *********************************************************
// Buttons
// *********************************************************


// Buttons default

.btn,
.btn:link,
.btn:visited {
    font-size: rem(14);
    height: rem(33);
    min-width: rem(165);
    line-height: rem(33);
    border-radius: 0;
    padding: 0 rem(19);
    letter-spacing: em(6);
    text-transform: uppercase;
    background: transparent;

    // Style for icons in button

    .icon,
    [class^="icon-"],
    [class*=" icon-"] {
        transition: transform 0.1s ease-in-out;
        backface-visibility: hidden;
    }
}

//////////////////////
// BUTTONS SIZE
//////////////////////

.btn--large,
.btn--large:link,
.btn--large:visited {
    font-size: rem(18);
    height: rem(74);
    min-width: rem(165);
    line-height: rem(74);
}

//////////////////////
// BUTTONS TYPE
//////////////////////

// Button primary

.btn--primary,
.btn--primary:link,
.btn--primary:visited {
    color: #1B1B1B;
    background: $primary-color;
}

// Button primary hover

.btn--primary:hover {
    @media only screen and (min-width: $small-device), (any-hover: hover) {

        &:hover {
            color: #1B1B1B;
            background: darken($color: $primary-color, $amount: 7%);
        }
    }
}

// Button white

.btn--white,
.btn--white:link,
.btn--white:visited {
    color: #1B1B1B;
    background: #FFFFFF;
}

// Button white hover

.btn--white:hover {
    @media only screen and (min-width: $small-device), (any-hover: hover) {

        &:hover {
            color: #000000;
            background: $primary-color;
        }
    }
}

// Button black

.btn--black,
.btn--black:link,
.btn--black:visited {
    color: #FFFFFF;
    background: #000000;
}

// Button black hover

.btn--black:hover {
    @media only screen and (min-width: $small-device), (any-hover: hover) {

        &:hover {
            color: #000000;
            background: $primary-color;
        }
    }
}

// Button search

.btn--search,
.btn--search:link,
.btn--search:visited {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: rem(24);
    color: #FFFFFF;
    height: initial;
    min-width: initial;
    padding: 0;
    letter-spacing: 0;
    line-height: 0;
    background: transparent !important;
}

// Button search hover

.btn--search:hover {
    @media only screen and (min-width: $small-device), (any-hover: hover) {

        &:hover {
            color: $primary-color;
        }

        &:hover .icon {
            transform: scale(0.9);
        }
    }
}

// Button search dark

.btn--search-dark,
.btn--search-dark:link,
.btn--search-dark:visited {
    color: #000000;
}

// Button search bg

.btn--search-bg,
.btn--search-bg:link,
.btn--search-bg:visited {
    font-size: rem(32);
    height: rem(58);
    width: rem(58);
    background: $primary-color;
    color: #000000;
    transition: border-radius 0.3s ease-in-out;

    .icon {
        transition: transform 0.2s cubic-bezier(0, 0.97, 0.38, 0.77);
    }
}

// Button search bg hover

.btn--search-bg:hover {
    @media only screen and (min-width: $small-device), (any-hover: hover) {

        &:hover {
            background: #FFFFFF;
            color: #000000;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
        }

        &:hover .icon {
            transform: scale(1) rotate(360deg);
        }
    }
}

// Button close (x)

.btn--close,
.btn--close:link,
.btn--close:visited {
    display: block;
    position: relative;
    width: rem(32);
    height: rem(20);
    z-index: 999999;
    min-width: initial;
    padding: 0;
    letter-spacing: 0;
    line-height: 0;
    transition: transform 0.2s ease-in-out;
}

.btn--close::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: rem(2);
    height: rem(2);
    top: 0;
    width: 100%;
    right: 0;
    background-color: #FFFFFF;
    transition: all 0.2S ease-in-out;
    transform: translateY(8px) translateX(0) rotate(225deg);
}

.btn--close::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: rem(2);
    height: rem(2);
    top: rem(16);
    width: 100%;
    right: 0;
    background-color: #FFFFFF;
    transition: all 0.2S ease-in-out;
    transform: translateY(-8px) translateX(0) rotate(-225deg);
}

// Button close hover

.btn--close:hover {
    @media only screen and (min-width: $small-device), (any-hover: hover) {

        &:hover {
            transform: scale(0.9);
        }
    }
}

// Button close (x) dark

.btn--close-dark::before,
.btn--close-dark::after {
    background-color: #000000;
}
