// *********************************************************
// Layout
// *********************************************************

// Custom edit

// Add class for remove padding

.no-padding {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
        padding: 0;
    }
}

// Max width container

.container {
    max-width: rem(1137);
}