// *********************************************************
// Line
// *********************************************************

// Line decoration

.line--top {
    position: relative;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: rem(24);
        width: 60%;
        max-width: rem(3500);
        background: $primary-color;
    }
}

.line--bottom {
    position: relative;

    &::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: rem(24);
        width: 60%;
        max-width: rem(3500);
        background: $primary-color;
    }
}

.line--bottom--bg {
    position: relative;

    &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: rem(24);
        width: 60%;
        max-width: 100%;
        background: #FFFFFF;
        z-index: 1;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: rem(24);
        width: 60%;
        max-width: rem(3500);
        background: $primary-color;
        z-index: 2;
    }
}

.line--bottom-left {
    position: relative;

    &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: rem(24);
        width: 60%;
        max-width: rem(3500);
        background: $primary-color;
    }
}