// *********************************************************
// Hamburger icon
// *********************************************************

.hamburger {
    // Hamburger wrapper

    display: block;
    position: relative;
    width: rem(32);
    height: rem(20);
    z-index: 999999999;

    // Hamburger all line

    &__line {
        background-color: #FFFFFF;
        border-radius: rem(2);
        display: block;
        height: rem(2);
        right: 0;
        position: absolute;
        transition: all 0.2S ease-in-out;
        width: 100%;
    }

    // First line

    &__line--top {
        top: 0;
        width: 80%;
    }

    // Middle line

    &__line--middle {
        top: rem(8);
    }

    // Last line

    &__line--bottom {
        top: rem(16);
        width: 60%;
    }

  
    // Style on hover only large device and no touch device

    @media only screen and (min-width: $small-device), (any-hover: hover) {

        &:hover &__line {
            width: 100%;
        }  
    }

    // Style on active hamburger

    
    &--active &__line {
        width: 100%;
        background: #000000;

        @media only screen and (max-width: $medium-device) {
            background: #FFFFFF;
        }
    }

    &--active &__line--top{
        transform: translateY(8px) translateX(0) rotate(225deg);
    }
    
    &--active &__line--middle {
        opacity: 0;
    }
    
    &--active &__line--bottom {
        transform: translateY(-8px) translateX(0) rotate(-225deg);
    }
}

// Hamburger black

.hamburger--black {

    .hamburger__line {
        background-color: #000000;
    }
}

.hamburger--black.hamburger--active {

    .hamburger__line {
        @media only screen and (max-width: $medium-device) {
            background-color: #FFFFFF;
        }
    }
}