// *********************************************************
// Logistics
// *********************************************************

// Logistics info section

.logistics__info {

    .card {
        padding-top: rem(43);
        margin-bottom: rem(-74);
        max-width: rem(480);

        @media only screen and (max-width: $medium-device) {
            margin-bottom: 0;
            margin: 0 auto;
        }
    }

    .card .card__heading {
        font-family: "Khand";
        font-size: rem(38);
        font-weight: 500;
    }

    .card .card__text {
        font-family: "Khand";
        font-size: rem(20);
        margin-bottom: rem(24);
    }

    .card .card__text:last-child {
        margin-bottom: rem(45);
    } 

    .card__wrapper {
        padding: 0 rem(62);

        @media only screen and (max-width: $medium-device) {
            padding: 0 rem(30);
            overflow-y: initial;
        }
    }

    .card__btn-wrapper {
        margin-top: 0;
    }

    // Info header

    &-header {
        position: relative;
        // background-image: url("../images/page/logistics_sm.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        padding-top: rem(200);
        z-index: 2;


        @media only screen and (min-width: $small-device) {

            // background-image: url("../images/page/logistics_md.jpg");
        }

        @media only screen and (min-width: $medium-device) { 
            // background-image: url("../images/page/logistics_lg.jpg");
            background-attachment: fixed;
        }

        @media only screen and (min-width: $large-device) {

            // background-image: url("../images/page/logistics.jpg");
        }
    }

    &-paragraph {
        font-family: "Titillium Web";
        font-size: rem(18);
        font-weight: 300;
        color: #FFFFFF;
        margin-bottom: rem(34);
    }

    &-paragraph:last-child {
        margin-bottom: rem(82);

        @media only screen and (max-width: $medium-device) {
            margin-bottom: rem(34);
        }
    }
    

    &-text {
        font-size: rem(20);
        font-weight: 300;
        line-height: 1.5;
    }

    &-body {
        padding-top: rem(65);
        padding-bottom: rem(70);
        background: #000000;
    }

    &-body [class~="col"]:first-child &-text-wrapper &-paragraph:nth-child(odd), 
    &-body [class*="col-"]:first-child &-text-wrapper &-paragraph:nth-child(odd) {
        color: $secondary-color;
    }

    &-body [class~="col"]:last-child &-text-wrapper, 
    &-body [class*="col-"]:last-child &-text-wrapper {
        margin-top: rem(52);
        max-width: rem(480);

        @media only screen and (max-width: $medium-device) {
            margin: 0 auto;
            max-width: none;
        }
    }
}