// *********************************************************
// Footer
// *********************************************************

.footer {
    color: #FFFFFF;
    background: #000000;
    padding: rem(68) 0;
    min-height: rem(196);

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__list--info {
        font-family: "Titillium Web";
        font-size: rem(12);
        font-weight: normal;
    }

    &__list--social {
        display: flex;
    }

    &__social-item:not(:last-child) {
        margin-right: rem(16);
    }

    &__social-link {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: rem(20);
        color: #000000;
        height: rem(34);
        width: rem(34);
        border-radius: 50%;
        text-decoration: none !important;
        z-index: 1;
        
        .icon {
            transition: transform 0.2s ease-in-out;
            backface-visibility: hidden;
        }

        @media only screen and (min-width: $small-device), (any-hover: hover) {

            &:hover {
                color: $primary-color;
            }
            
            &:hover .icon {
                transform: scale(1.8);
            }

            &:hover::before {
                transform: scale(0);
            }
        }
    }

    &__social-link::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        height: rem(34);
        width: rem(34);
        background: #FFFFFF;
        z-index: -1;
        transition: transform 0.2s ease-in-out;

    }
}