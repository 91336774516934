// *********************************************************
// Navigation
// *********************************************************

.navigation {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    min-height: 100%;
    min-height: 100vh;
    height: 100%;
    padding: rem(120) rem(80);
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
    z-index: 9999999;

    * {
        opacity: 0;
    }

    &::before {
        content: "";
        position: fixed;
        display: block;
        height: 100%;
        width: 0;
        top: 0;
        right: 0;
        background: #FFFFFF;
        z-index: -1;
    }
    
    @media only screen and (max-width: $large-device) {
        width: 70%;
    }

    @media only screen and (max-width: $medium-device) {
        padding: rem(110) 0;
        right: auto;
        left: 0;
        width: rem(290);
    }

    @media only screen and (max-width: em(350)) {
        width: 80%;
    }

    &::-webkit-scrollbar {
        width: rem(5);
    }
    
    /* Track */

    &::-webkit-scrollbar-track {
        background: #FFFFFF;
    }
    
    /* Handle */

    &::-webkit-scrollbar-thumb {
        background: $primary-color; 
    }
    
    /* Handle on hover */

    &::-webkit-scrollbar-thumb:hover {
        background: $primary-color; 
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;

        @media only screen and (max-width: $medium-device) {
            padding: 0 rem(60) 0 rem(30);
        }
    }

    &__list-btn {
        display: flex;
        overflow: hidden;
        margin: 0;
        margin-top: rem(92);
        padding: 0;
        list-style: none;

        @media only screen and (max-width: $medium-device) {
            justify-content: center;
            margin-top: rem(38);
        }
    }

    &__list-btn li {
       
        @media only screen and (max-width: $medium-device) {
            min-width: rem(145);
            width: 50%;
        }
    }

    &__list-btn .btn  {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 0.8;
        height: rem(56);
        width: rem(232);

        @media only screen and (max-width: $medium-device) {
            height: rem(34);
            width: 100%;
            min-width: auto;
        }
    }

    &__item:not(:last-child) {
        margin-bottom: rem(8);
        @media only screen and (min-width: $small-device), (any-hover: hover) {
            // margin-bottom: rem(8);
        }
    }

    &__list &__link {
        line-height: 0.9;
    }
    
    &__link,
    &__link:link,
    &__link:visited {
        font-size: rem(55.5);
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: none;
        white-space: nowrap;
        color: #000000;


        // Hover style for old browser that no support background-clip: text
        @media only screen and (min-width: $small-device), (any-hover: hover) {

            &:hover {
                color: $primary-color;
            }
        }

        @media only screen and (max-width: $medium-device) {
            font-size: rem(29);
        }

        // Style for all browser that support background-clip: text
        @supports (background-clip: text) or (-webkit-background-clip: text) {
            color: transparent;
            -webkit-background-clip: text;
            background-clip: text;
            background-image: linear-gradient(to right, #000000 50%,$primary-color 50%);
            background-size: 200%;
            background-position: 0% 0;
            transition: background-position 0.3s cubic-bezier(0, 0.83, 1, 1);

            // Hover style for all browser that support background-clip: text
            @media only screen and (min-width: $small-device), (any-hover: hover) {

                &:hover {
                    color: transparent;
                    background-position: -100% 0;
                    text-decoration: none;
                }
            }
        }
    }

    // Style for active link

    &__link--active,
    &__link--active:link,
    &__link--active:visited {
        color: $primary-color !important;
        background-image: none !important;
        background-clip: initial !important;
    }

    &__sub-list {
        display: none;
        list-style: none;
        padding: 0;
        margin: rem(4) 0;

        @media only screen and (max-width: $medium-device) {
            margin: rem(10) 0;
        }
    }

    &__sub-item:not(:last-child) {
        margin-bottom: rem(8);
    }

    // Sub link in accordion list

    &__sub-link,
    &__sub-link:link,
    &__sub-link:visited {
        font-size: rem(43);
        font-weight: 300;
        color: #969696;
        text-decoration: none;
        line-height: 1;

        @media only screen and (min-width: $small-device), (any-hover: hover) {

            &:hover {
                color: #000000;
            }
        }

        @media only screen and (max-width: $medium-device) {
            font-size: rem(23);
            // padding: rem(4) 0;
        }
    }

    // Style for active sub-link

    &__sub-link--active,
    &__sub-link--active:link,
    &__sub-link--active:visited {
        font-weight: 500;
        color: #000000 !important;
    }

    // Style for drop down link (arrow)

    &__link--drop-down {
        position: relative;
    }

    // Arrow icon for drop down link

    &__link--drop-down::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        left: rem(-34);
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../images/icon/arrow.svg");
        height: rem(20);
        width: rem(20);
        transform: translateY(-50%) rotate(180deg);
        transition: transform 0.1s ease-in-out;

        @media only screen and (max-width: $medium-device) {
            height: rem(10);
            width: rem(10);
            left: rem(-18);
        }
    }

    // Arrow drop down link active (rotate)

    &__link--drop-down-active::before {
        transform: translateY(-50%) rotate(0deg);
    }

    @media only screen and (min-width: $medium-device), (any-hover) {
        
        .btn:hover {
            background: #A7A7A7;
        }
    }
}


// Navigation open (active)

.navigation--open {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s linear;

    * {
        opacity: 1;
        transition: opacity 0.5s linear;
    }

    &::before {
        width: 50%;
        transition: width 0.5s cubic-bezier(0.06, 1.23, 0.58, 1);

        @media only screen and (max-width: $large-device) {
            width: 70%;
        } 

        @media only screen and (max-width: $medium-device) {
            width: rem(290);
            right: auto;
            left: 0;
        }

        @media only screen and (max-width: em(350)) {
            width: 80%;
        }
    
    }
}

// Off body scroll on open navigation

.body--hiddne {
    overflow: hidden;
}

.header--navigation-active::before {
    content: "";
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;
    background: hsla(0, 0%, 0%, 0.8);
    z-index: 9;
}