// *********************************************************
// Pages heading
// *********************************************************

// Monitoring header 

.pages-header {

    &__wrapper {
        text-align: center;
        margin-top: rem(44);
        margin-bottom: rem(54);
    }

    &__wrapper .icon {
        font-size: rem(130);
        height: rem(108);

        @media only screen and (max-width: $medium-device) {
            font-size: rem(120);
        }

        @media only screen and (max-width: $small-device) {
            font-size: rem(80);
        }
    }

    &__heading {
        font-size: rem(81);
        font-weight: 600;
        margin-bottom: 0;
        color: #000000;
        line-height: 1;
        text-transform: uppercase;

        @media only screen and (max-width: $medium-device) {
            font-size: rem(64);
        }

        @media only screen and (max-width: $small-device) {
            font-size: rem(48);
        }
    }
}