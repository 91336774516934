// *********************************************************
// Header
// *********************************************************

.header {
    width: 100%;
    margin-top: rem(30);
    padding: rem(34) 0;

    @media only screen and (max-width: $medium-device) {
        margin: rem(28) 0;
    }

    &__logo-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(174);
        height: rem(58);

        @media only screen and (max-width: $medium-device)  {
            height: auto;
            width: auto;
        }
    }

    &__logo-image {
        max-width: 100%;
        height: auto;

        @media only screen and (max-width: $medium-device)  {
            max-height: rem(50);
            margin-right: auto;
        }

        @media only screen and (min-width: $small-device), (any-hover: hover) {

            &:hover {
                opacity: 0.8;
            }
        }
    }

    @media only screen and (min-width: $medium-device), (any-hover) {
        
        .btn:hover {
            background: #A7A7A7;
        }
    }
}

.header--white {
    position: relative;
    margin-top: 0;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4); 

    @media only screen and (max-width: $medium-device) {
        margin: 0;
    }
}