// *********************************************************
// Inputs
// *********************************************************

.input {
    border: 0;
    height: rem(58);
    width: 100%;
    text-transform: uppercase;
    padding: 0 rem(15);
    border-radius: 0;
}

.input__search {
    position: relative;
    border: 0;
    border-radius: 0;
    background: transparent;
    outline: 0;
    width: 0;;
}

.input__search--white {
    color: #FFFFFF;
}


// *********************************************************
// Form
// *********************************************************

.form__search {
    position: relative;
    display: flex;
    align-items: center;
    width: rem(27);
    transition: width 0.3s cubic-bezier(0, 0.93, 0.46, 0.99);

    &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: rem(-12);
        width: 100%;
        height: 1px;
        background: transparent;
    }

    @media only screen and (max-width: $medium-device) {
        display: none;
    }

    .btn {
        display: block;
    }
}

.form__search-btn {
    flex-shrink: 0;
    height: rem(27);
    width: rem(27);
    overflow: hidden;
}

.form__search-btn--active .btn:first-child {
    margin-top: rem(-29);
}

.form__search-btn--active .btn:last-child {
    color: $primary-color;
}

.form__search--focus {
    width: rem(214);

    &::before {
        background: rgba(145, 152, 165, 0.555);
    }

    @media only screen and (max-width: $large-device) {
        width: rem(150);
    }

    .input__search {
        width: 100%;
        padding-left: rem(8);
    }
}
