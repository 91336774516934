// *********************************************************
// Hero
// *********************************************************

.hero {
    position: relative;

    &::before {
        display: block;
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #020102;
        z-index: -1;

        @media only screen and (max-width: $medium-device) {
            display: none;
        }
    }


    &__wrapper {
        position: relative;
        padding-top: rem(160);
        padding-bottom: rem(100);

        @media only screen and (max-width: $medium-device) {
            padding-top: rem(60);
        }

    }

    &__text-wrapper {
        color: #FFFFFF;
    }

    &__heading {
        font-size: rem(92.78);
        font-weight: 600;
        line-height: 1;
        margin-left: rem(89);
        margin-bottom: rem(71);
        text-transform: uppercase;

        @media only screen and (max-width: $large-device) {
            font-size: rem(64);
        }

        @media only screen and (max-width: $medium-device) {

            margin-left: rem(0);
        }

        @media only screen and (max-width: $small-device) {
            font-size: rem(48);
        }
    }

    &__paragraph {
        font-size: rem(18);
        max-width: rem(428);
        line-height: 1.8;
        text-transform: uppercase;
        letter-spacing: 0.25em;
        margin-left: rem(312);

        @media only screen and (max-width: $medium-device) {
            font-size: rem(16);
            margin-left: rem(100);
        }

        @media only screen and (max-width: $small-device) {
            margin-left: 0;
        }
    }

    &__paragraph:nth-child(odd) {
        color: $secondary-color;   
    }

    &__btn-warpper {
        margin-top: rem(47);
        margin-left: rem(100);

        @media only screen and (max-width: $small-device) {
            margin-left: 0;
        }
    }

    &__btn-warpper .btn {
        width: rem(158);
    }

    @media only screen and (min-width: $medium-device), (any-hover) {
        
        .btn:hover {
            background: #A7A7A7;
        }
    }
}