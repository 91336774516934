// *********************************************************
// Eco-drive
// *********************************************************

// Eco-drive info section

.ecodrive__info {

    .card {
        margin-bottom: rem(-74);
        padding-top: rem(62);
        max-width: rem(417);

        @media only screen and (max-width: $medium-device) {
            margin-bottom: 0;
            margin: auto;
        }

        @media only screen and (max-width: $small-device) {
            margin-top: 15rem;
        }
    }

    .card .card__heading {
        font-family: "Khand";
        font-size: rem(38);
        font-weight: 500;
    }

    .card .card__text {
        font-family: "Khand";
        font-size: rem(20);
    }

    .card__wrapper {
        padding-left: rem(58);
        padding-right: rem(38);
        margin-bottom: rem(28);

        @media only screen and (max-width: $medium-device) {
            padding-left: rem(30);
            padding-right: rem(30);
            overflow-y: initial;
        }
    }

    .card__btn-wrapper {
        margin-top: rem(20);
    }

    // Info header

    &-header {
        position: relative;
        // background-image: url("../images/page/ekodrive_sm.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        z-index: 2;

        .row {
            min-height: rem(200);
            
            @media only screen and (min-width: $small-device) {
                min-height: rem(300);  
            }
    
            @media only screen and (min-width: $large-device) {
                min-height: rem(678);
            }
        }

        @media only screen and (min-width: $small-device) {

            // background-image: url("../images/page/ekodrive_md.jpg");
        }

        @media only screen and (min-width: $medium-device) { 
            // background-image: url("../images/page/ekodrive_lg.jpg");
            background-attachment: fixed;
        }

        @media only screen and (min-width: $large-device) {
            // background-image: url("../images/page/ekodrive.jpg");
        }
    }

    &-heading {
        font-family: "Khand";
        font-size: rem(30);
        font-weight: normal;
        color: #FFFFFF;
        text-transform: uppercase;
    }

    &-paragraph {
        font-family: "Titillium Web";
        font-size: rem(18);
        font-weight: 300;
        color: #FFFFFF;
    }

    &-paragraph:not(:last-child) {
        margin-bottom: rem(28);
    }

    &-text {
        font-size: rem(20);
        font-weight: 300;
        line-height: 1.5;
    }

    &-body {
        padding-top: rem(92);
        padding-bottom: rem(67);
        background: #000000;
    }
}

// Eco-drive sub info section

.ecodrive__sub-info {
    position: relative;

    &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: rem(210);
        background: $primary-color;
    }

    .card {
        padding-top: rem(56);
        padding-bottom: rem(92);
        max-width: rem(569);
        margin-bottom: rem(22);

        @media only screen and (max-width: $medium-device) {
            margin: 0 auto; 
        }
    }

    .card__wrapper {
        padding-left: rem(36);
        padding-right: rem(47);
        overflow-y: initial;

        @media only screen and (max-width: $medium-device) {
            padding-left: rem(30);
            padding-right: rem(30);
        }
    }

    .card__text:not(:last-child) {
        margin-bottom: rem(24);
    }

    .card__text:nth-child(even) {
        color: $secondary-color;
    }
}


// Eco-drive footer (background only)

.ecodrive__footer {
    position: relative;
    min-height: rem(200);
    // background-image: url("../images/page/ekologicka-jazda_sm.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 2;


    @media only screen and (min-width: $small-device) {
        min-height: rem(300);  
        // background-image: url("../images/page/ekologicka-jazda_md.jpg");
    }

    @media only screen and (min-width: $medium-device) {
        min-height: rem(400);  
        // background-image: url("../images/page/ekologicka-jazda_lg.jpg");
        background-attachment: fixed;
    }

    @media only screen and (min-width: $large-device) {
        min-height: rem(611);  
        // background-image: url("../images/page/ekologicka-jazda.jpg");
    }

    .card {
        padding-top: rem(56);
        padding-bottom: rem(49);
        position: relative;
        top: rem(-185);
        max-width: rem(569);

        @media only screen and (max-width: $large-device) {
            top: 0;
            margin: rem(20) 0;
        }

        @media only screen and (max-width: $medium-device) {
            margin: rem(64) auto; 
        }
    }

    .card__wrapper {
        padding-left: rem(36);
        padding-right: rem(47);
        overflow-y: initial;

        @media only screen and (max-width: $medium-device) {
            padding-left: rem(30);
            padding-right: rem(30);
        }
    }

    .card__text:not(:last-child) {
        margin-bottom: rem(24);
    }

    .card__text:nth-child(even) {
        color: $secondary-color;
    }
}