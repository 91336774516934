// *********************************************************
// Home
// *********************************************************

// Default style

.home {
    position: relative;
}

// Default main heading

.home__heading {
    display: flex;
    align-items: flex-start;
    font-size: rem(64);
    line-height: 1;
    margin-bottom: rem(46);
    text-transform: uppercase;

    @media only screen and (max-width: $medium-device) {
        font-size: rem(34);
    }
   

    &-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .icon {
            font-size: rem(150);
            color: $primary-color;
            height: rem(96);
            margin-right: rem(24);
    
            @media only screen and (max-width: $medium-device) {
                font-size: rem(75);
                height: rem(55);
            }
        }
    }
}

// Profil company section

.home__profil {
    min-height: rem(610);
    background-color: #FFFFFF;

    @media only screen and (min-width: $large-device) {
        // background-image: url("../images/page/profil-spolocnosti.jpg");
        background-repeat: no-repeat;
        background-position: top right -13rem;
        background-size: contain;
    }

    @media only screen and (max-width: $medium-device) {
        min-height: auto;     
        padding-top: rem(64);
        padding-bottom: rem(64);
    } 

    &-text-wrapper {
        margin-top: rem(115);

        @media only screen and (max-width: $medium-device) {
            margin-top: 0;
        }
    }

    &-text {
        font-size: rem(20);
        max-width: rem(588);
        margin-left: rem(176);

        @media only screen and (max-width: $medium-device) {
            font-size: rem(16);
            margin-left: rem(0);
        }
    }

    &-text:not(:last-child) {
        margin-bottom: rem(46);
    }

    &-text:last-child {
        margin-bottom: rem(166);

        @media only screen and (max-width: $medium-device) {
            margin-bottom: 0;
        }
    }

}

// References section

.home__references {
    background: #F4F4F4;
    min-height: rem(838);

    &-wrapper {
        padding-left: rem(44);
        margin-top: rem(109);


        @media only screen and (max-width: $medium-device) {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: rem(64);
            padding-bottom: rem(64);
            padding-left: rem(15);
        }
    }

    &-text {
        font-size: rem(16);
        font-weight: 300;
        line-height: 1.5;
        max-width: rem(540);
        margin-bottom: rem(124);

        @media only screen and (max-width: $medium-device) {
            margin-bottom: 0;
        }
    }

    &-heading {
        font-size: rem(37);
        margin-bottom: 0;

    }

    &-sub-heading {
        font-size: rem(20);
        font-weight: 300;
        padding-bottom: rem(16);
        margin-bottom: rem(30);
        border-bottom: 1px solid #000000;

    }

    .card {
        position: absolute;
        top: rem(-120);
        left: rem(15);
        height: calc(100% + 216px);
        max-width: rem(570);
        padding-top: rem(86);
        padding-bottom: rem(59);

        @media only screen and (max-width: $medium-device) {
            position: relative;
            top: 0;
            left: 0;
            height: 100%;
            max-width: 100%;
        }
    }

    .card__wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-left: rem(69);
        padding-right: rem(60);

        @media only screen and (max-width: $medium-device) {
            padding-left: rem(30);
            padding-right: rem(30);
        }
    }

    .card__heading {
        font-size: rem(37);
        margin-bottom: rem(44);
    }

    .card__text {
        font-family: "Titillium Web";
        font-size: rem(16);
        font-weight: 300;
    }

    .btn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: rem(56);
        width: rem(232);
        line-height: 0;
    }
}

// USP (unique selling proposition) section

.home__usp {

    padding-top: rem(40);
    background: #FFFFFF;

    @media only screen and (max-width: $medium-device) {
        padding-top: 0;
    }

    .home__heading {
        margin-bottom: 0;
    }

    .home__heading-wrapper {
        margin-top: rem(106);
        margin-bottom: rem(59);

        @media only screen and (max-width: $medium-device) {
            margin-top: rem(64);
            margin-bottom: rem(64);
        }
    }
  

    &-bg {
        background: #004583;
        padding-top: rem(48);
        padding-bottom: rem(110);

        @media only screen and (min-width: $medium-device) {
            background-image: url("../images/page/preco-si-vybrat-nas.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-attachment: fixed;
        }
    }

    &-icon {
        height: rem(85);
        margin-bottom: rem(20);
    }

    &-icon .icon {
        font-size: rem(84);
        color: $primary-color;
    }

    &-heading {
        font-family: "Khand";
        font-size: rem(20);
        font-weight: 600;
        color: #FFFFFF;
        min-height: rem(50);
        margin-bottom: rem(10);
    }

    &-text {
        font-family: "Titillium Web";
        font-size: rem(18);
        font-weight: 300;
        color: $primary-color;

        &::-moz-selection { 
            color: #FFFFFF;
            background: transparent;
        }
        
        &::selection {
            color: #FFFFFF;
            background: transparent;
        }
    }
}
